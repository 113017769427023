import React, { Component } from 'react'

/**
 * Varolamayan URL yoluna gidilince gözüken sayfa. 
 */
class NotFound extends Component {
	render() {
		return (
			<div className='text-center p-5'>
				<h1>Page Not Found :/</h1>
			</div>

		)
	}
}

export default NotFound;	