import React, { Component } from 'react'
import logo from '../media/logo.png';

import { Link } from 'react-router-dom';

/**
 * Sitenin en üstünde bulunan, ekran küçülünce toggle-bar seçeneği çıkaran navigasyon bar komponenti.
 */
class Navbar extends Component {
	render() {
		return (
			<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
				<div className="container-fluid">
					<Link to="/" className="navbar-brand">
						<img src={logo} alt="Starbucks Logo" width="80" className='mx-5'/>
					</Link>

					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse navbar-collapse text-center" id="navbarNav">
						<ul className="navbar-nav">
							<Link className="nav-item text-decoration-none mx-1" to="/"><li className="nav-link nav-link active ">Home</li></Link>
							<Link className="nav-item text-decoration-none mx-1" to="/menu"><li className="nav-item nav-link active">Menu</li></Link>
							<Link className="nav-item text-decoration-none mx-1" to="/about"><li className="nav-item nav-link active">About</li></Link>
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

export default Navbar;