import './App.css';

import Navbar from './Navbar';
import Footer from './Footer';

import Routes from './routes/Routes'

import { HashRouter } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Navbar />
        <Routes />
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
