import React, { Component } from 'react'

import { Link } from 'react-router-dom';

import './CoffeeCard.css';

/**
 * Menüdeki her bir kahveyi gösteren komponent.
 */
class CoffeeCard extends Component {

	render() {
		return (
			<Link to={"/menu/" + this.props.id} className='text-decoration-none text-dark'>
				<div className="card h-100">
					<img className="card-img-top" src={this.props.image} alt="..." width="100" />

					<div className="card-body p-4">
						<div className="text-center">
							<h5 className="fw-bolder">{this.props.name}</h5>
							$ {this.props.price}
						</div>
					</div>
				</div>
			</Link>
		)
	}
}

export default CoffeeCard;