import React, { Component } from 'react'
import { useParams } from 'react-router-dom';

import './Coffee.css';

import image1 from '../../media/kahveler/1.jpg';
import image2 from '../../media/kahveler/2.jpg';
import image3 from '../../media/kahveler/3.jpg';

// id parameteresini almak için kullanılan fonksiyon
function withParams(Component) {
	return props => <Component {...props} params={useParams()} />;
}

/**
 * Kahve sayfasını gösterir.
 */
class Coffee extends Component {
	constructor(props) {
		super(props)
		this.coffeelist = [{
			id: 1, name: "Latte", price: "14", image: image1,
			desc: "In Italy, caffè latte is almost always prepared at home, for breakfast only. The coffee is brewed with a stovetop moka pot and poured into a cup containing heated milk. (Unlike the 'international' latte drink, the milk in the Italian original is generally not foamed, and sugar is added by the drinker, if at all). "
		},
		{
			id: 2, name: "Americano", price: "14", image: image2,
			desc: "'Americano' means 'American' in Italian, Spanish and Portuguese. It entered the English language from Italian in the 1970s.'Caffè Americano' specifically is Italian for 'American coffee'.There is a popular but unconfirmed belief that the name has its origins in World War II when American G.I.s in Italy diluted espresso with hot water to approximate the coffee to which they were accustomed."
		},
		{
			id: 3, name: "Frappe", price: "18", image: image3,
			desc: "The name frappé comes from French, where it describes drinks chilled with ice. Beginning in the 19th century, a variety of cold coffee drinks named café frappé (à la glace) are documented, some similar to slushes, others more like iced coffee. "
		}]
	}

	render() {
		let { id } = this.props.params;
		let coffee = this.coffeelist[id - 1]

		return (
			<div className="bg-ddd d-flex flex-lg-row flex-column justify-content-center align-items-center m-5">
				<img src={coffee.image} alt="" className='coffee-image' />
				<div className='w-25 col-md-100  m-5'>
					<h2>{coffee.name}</h2>
					<p>{coffee.desc}</p>
				</div>
			</div>
		);
	}
}

export default withParams(Coffee);