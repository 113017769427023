import React, { Component } from 'react'

/**
 * Sitenin en altında gözüken komponent.
 */
class Footer extends Component {
	render() {
		return (
			<div className="container">
				<footer className="py-3 my-4">
					<p className="text-center text-muted">(206) 522-8361 <br/>
						6501 Ravenna Ave NE #203<br/>
						Seattle, Washington(WA), 98115</p>
					<p className="text-center text-muted">© 2022 Star Wars Coffee Inc</p>
				</footer>
			</div>
		);
	}
}

export default Footer;