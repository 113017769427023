import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Menu from '../pages/Menu';
import Coffee from '../pages/Coffee';
import About from '../pages/About';
import NotFound from '../pages/NotFound';

/**
 * Single page olması için kullanılan sayfaları tutan router.
 */
const Routes = () =>
	<Switch>
		<Route path="/" element={<Home />} />
		<Route path="/menu" element={<Menu />} />
		<Route path="/menu/:id" element={<Coffee />} />
		<Route path="/about" element={<About />} />
		<Route path="*" element={<NotFound />} />
	</Switch>

export default Routes;