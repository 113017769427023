import React, { Component } from 'react'

import bgimage from '../../media/bg.jpg';
import shape from '../../media/shape.svg';

import { Link } from 'react-router-dom';

import './Home.css'

/**
 * "/" yolundaki sayfayı gösterir.
 */
class Home extends Component {
	render() {
		return (
			<section className="header-area header-one">
				<div className="header-content-area">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 col-12">
								<div className="header-wrapper">
									<div className="header-content">
										<h1 className="header-title">
											SOMETHING FRESH IS ALWAYS BREWING HERE
										</h1>
										<p className="text-lg">
											Stop wasting time and money by drinking normal coffee
											that is not strong enough. Go and check the menu!
										</p>
										<div className="header-btn rounded-buttons">
											<Link to="/menu" className="btn primary-btn-outline btn-lg">GO TO MENU</Link>
										</div>
									</div>
								</div>
							</div>

							<div className="col-lg-6 col-12">
								<div className="header-image d-none d-lg-block">
									<div className="image">
										<img src={bgimage} alt="Header" />
									</div>
								</div>
							</div>

						</div>
					</div>
					<div className="header-shape">
						<img src={shape} alt="şekül" />
					</div>
				</div>
			</section>
		)
	}
}

export default Home;