import React, { Component } from 'react';

import CoffeeCard from '../CoffeeCard';

import image1 from '../../media/kahveler/1.jpg';
import image2 from '../../media/kahveler/2.jpg';
import image3 from '../../media/kahveler/3.jpg';

/**
 * Kahve menüsünü gösteren sayfa.
 */
class Menu extends Component {
	constructor(props) {	
		super(props)
		
		// kahve listesini tutar
		this.coffeelist = [{ id: 1, name: "Latte", price: "14", image: image1 }, { id: 2, name: "Americano", price: "14", image: image2 }, { id: 3, name: "Frappe", price: "18", image: image3 }]
	}

	handleCallback = (id) => {
		console.log(id)
	}

	render() {
		return (
			<section className="py-5">
				<div className="container px-4 px-lg-5 mt-5">
					<div className="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
						{this.coffeelist.map(coffee =>
							// listedeki her kahve için CoffeCard oluşturur
							<CoffeeCard handleCallback={this.handleCallback} key={coffee.id} id={coffee.id} name={coffee.name} price={coffee.price} image={coffee.image} />
						)}
					</div>
				</div>
			</section>

		)
	}
}

export default Menu;