import React, { Component } from 'react'

import './About.css';
import image from '../../media/suchashot.jpg';

/**
 * Hakkımızda sayfasını gösterir.
 */
class About extends Component {
	render() {
		return (
			<div className="aboutus-area">
				<div className="container">
					<div className="bg-ddd col-xs-12 d-flex flex-lg-row flex-column m-5 mx-auto align-items-md-center align-items-sm-center">
						<div className="mx-3">
							<div className="aboutus-image float-left">
								<img src={image} alt="" className='' />
							</div>
						</div>
						<div className=" d-flex flex-column justify-content-center">
							<div className="aboutus-content ">
								<h2>Our <span>Heritage</span></h2>
								<br/>
								<p>Our story begins in 1971 along the cobblestone streets of Seattle’s historic Pike Place Market. It was here where Starbucks opened its first store, offering fresh-roasted coffee beans, tea and spices from around the world for our customers to take home. Our name was inspired by the classic tale, “Moby-Dick,” evoking the seafaring tradition of the early coffee traders.</p>
								<p>Ten years later, a young New Yorker named Howard Schultz would walk through these doors and become captivated with Starbucks coffee from his first sip. After joining the company in 1982, a different cobblestone road would lead him to another discovery. It was on a trip to Milan in 1983 that Howard first experienced Italy’s coffeehouses, and he returned to Seattle inspired to bring the warmth and artistry of its coffee culture to Starbucks. By 1987, we swapped our brown aprons for green ones and embarked on our next chapter as a coffeehouse.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default About;